import React, { useMemo } from "react"
import styled from "styled-components"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

const Wrapper = styled.div`
  max-width: 100%;

  overflow: auto;

  th,
  td {
    &[data-centered="true"] {
      text-align: center;
    }
  }

  th {
    font-size: 0.8rem;
    letter-spacing: -0.2px;
    line-height: 1em;
  }

  td {
    transition: background 0.2s ease-in-out;

    &[data-centered="true"] {
      border-left: 1px solid ${props => props.theme.color.darkBg};
    }

    .check {
      font-weight: bold;
    }
  }

  tr:hover td {
    background-color: ${props => props.theme.color.mediumBg};
  }

  .responsiveTable {
    td {
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.color.darkBg} !important;
      }
    }

    .tdBefore {
      font-size: 0.8rem;
      line-height: 1em;
    }
  }
`

export default function InfoTable(props) {
  const columns = useMemo(
    () => [
      { header: "Country", accessor: "country" },
      { header: "Patent No.", accessor: "patentNumber" },
      { header: "B2 Blender", accessor: "b2Blender" },
      { header: "B4 Blender", accessor: "b4Blender" },
      { header: "B6 Blender", accessor: "b6Blender" },
      { header: "B7 Blender", accessor: "b7Blender" },
      { header: "PONY Blender", accessor: "ponyBlender" },
      { header: "Serving Cup", accessor: "servingCup" },
    ],
    []
  )

  const data = useMemo(
    () => [
      ["US", "", "", "", "X", "X", "", ""],
      ["PCT", "", "", "", "X", "X", "", ""],
      ["Australia", "2009313638", "X", "X", "X", "X", "X", "X"],
      ["Canada", "CA 2736153", "X", "X", "X", "X", "X", "X"],
      ["Germany", "602009025145.7", "X", "X", "X", "X", "X", "X"],
      ["EP", "2342138", "X", "X", "X", "X", "X", "X"],
      ["Spain", "2496691", "X", "X", "X", "X", "X", "X"],
      ["France", "", "X", "X", "X", "X", "X", "X"],
      ["United Kingdom", "2342138", "X", "X", "X", "X", "X", "X"],
      ["Ireland", "2342138", "X", "X", "X", "X", "X", "X"],
      ["Italy", "502014000000791", "X", "X", "X", "X", "X", "X"],
      ["Turkey", "TR201410734T4", "X", "X", "X", "X", "X", "X"],
      ["US", "8,336,731", "X", "X", "X", "X", "X", "X"],
      ["Australia", "", "", "X", "X", "", "", ""],
      ["Belgium", "2445622", "", "X", "X", "", "", ""],
      ["Canada", "2,770,364", "", "X", "X", "", "", ""],
      ["CA", "2,842,468", "", "X", "X", "", "", ""],
      ["Germany", "602010050072.1", "", "X", "X", "", "", ""],
      ["EP", "EP2445622", "", "X", "X", "", "", ""],
      ["Spain", "2.667.272", "", "X", "X", "", "", ""],
      ["France", "2445622", "", "X", "X", "", "", ""],
      ["United Kingdom", "2445622", "", "X", "X", "", "", ""],
      ["Italy", "TBD", "", "X", "X", "", "", ""],
      ["Netherlands", "E2445622", "", "X", "X", "", "", ""],
      ["US", "8,763,515", "", "X", "X", "", "", ""],
      ["Australia", "", "", "X", "X", "", "", ""],
      ["Belgium", "2446425", "", "X", "X", "", "", ""],
      ["Canada", "CA 2770371", "", "X", "X", "", "", ""],
      ["Germany", "2446425", "", "X", "X", "", "", ""],
      ["EP", "2446425", "", "X", "X", "", "", ""],
      ["Spain", "2446425", "", "X", "X", "", "", ""],
      ["France", "2446425", "", "X", "X", "", "", ""],
      ["United Kingdom", "2446425", "", "X", "X", "", "", ""],
      ["Italy", "2446425", "", "X", "X", "", "", ""],
      ["Netherlands", "2446425", "", "X", "X", "", "", ""],
      ["USA", "9,833,109", "", "X", "X", "", "", ""],
      ["USA", "9,420,917", "", "", "", "", "X", ""],
      ["AU", "", "", "", "", "", "X", ""],
      ["CA", "2,879,829", "", "", "", "", "X", ""],
      ["EP", "", "", "", "", "", "X", ""],
      ["US", "9,579,615", "", "", "", "", "X", ""],
      ["US", "9,386,882", "", "", "", "", "X", ""],
      ["Australia", "", "", "", "", "X", "", ""],
      ["Brazil", "", "", "", "", "X", "", ""],
      ["Belize", "", "", "", "", "X", "", ""],
      ["Canada", "", "", "", "", "X", "", ""],
      ["China", "", "", "", "", "X", "", ""],
      ["Colombia", "", "", "", "", "X", "", ""],
      ["Costa Rica", "", "", "", "", "X", "", ""],
      ["Dominican Republic", "", "", "", "", "X", "", ""],
      ["Europe", "", "", "", "", "X", "", ""],
      ["Guatemala", "", "", "", "", "X", "", ""],
      ["Hong Kong", "", "", "", "", "X", "", ""],
      ["Honduras", "", "", "", "", "X", "", ""],
      ["Indonesia", "", "", "", "", "X", "", ""],
      ["Israel", "", "", "", "", "X", "", ""],
      ["India", "", "", "", "", "X", "", ""],
      ["Japan", "", "", "", "", "X", "", ""],
      ["South Korea", "", "", "", "", "X", "", ""],
      ["Mexico", "", "", "", "", "X", "", ""],
      ["Malaysia", "", "", "", "", "X", "", ""],
      ["Nicaragua", "", "", "", "", "X", "", ""],
      ["Panama", "", "", "", "", "X", "", ""],
      ["Peru", "", "", "", "", "X", "", ""],
      ["Singapore", "", "", "", "", "X", "", ""],
      ["El Salvador", "", "", "", "", "X", "", ""],
      ["Thailand", "", "", "", "", "X", "", ""],
      ["United States", "10,299,628", "", "", "", "X", "", ""],
      ["US", "", "", "", "", "X", "", ""],
      ["South Africa", "", "", "", "", "X", "", ""],
      ["US", "7,144,150", "X", "X", "X", "", "", ""],
      ["US", "7,520,662", "X", "X", "X", "", "", ""],
      ["USA", "7,520,658", "X", "X", "X", "", "", ""],
      ["Australia", "", "", "X", "X", "", "", ""],
      ["Canada", "2,770,358", "", "X", "X", "", "", ""],
      ["Europe", "", "", "X", "X", "", "", ""],
      ["USA", "8,905,626", "", "X", "X", "", "", ""],
      ["Canada", "CA 135276", "", "", "", "", "", "X"],
      ["Australia", "201711891", "", "", "X", "", "", ""],
      ["Brazil", "", "", "", "X", "", "", ""],
      ["Switzerland", "", "", "", "X", "", "", ""],
      ["China", "ZL 2017301213832", "", "", "X", "", "", ""],
      ["United Kingdom", "6010476", "", "", "X", "", "", ""],
      ["Korea", "DM/095 599", "", "", "X", "", "", ""],
      ["Mexico", "54259", "", "", "X", "", "", ""],
      ["Singapore", "", "", "", "X", "", "", ""],
      ["Taiwan", "D188957", "", "", "X", "", "", ""],
      ["USA", "D807691", "", "", "X", "", "", ""],
      ["Vietnam", "26874", "", "", "X", "", "", ""],
      ["WO", "", "", "", "X", "", "", ""],
      ["Australia", "", "", "", "X", "", "", ""],
      ["Brazil", "", "", "", "X", "", "", ""],
      ["Canada", "", "", "", "X", "", "", ""],
      ["China", "", "", "", "X", "", "", ""],
      ["Europe", "", "", "", "X", "", "", ""],
      ["Indonesia", "", "", "", "X", "", "", ""],
      ["Israel", "", "", "", "X", "", "", ""],
      ["Japan", "", "", "", "X", "", "", ""],
      ["Korea", "", "", "", "X", "", "", ""],
      ["Mexico", "", "", "", "X", "", "", ""],
      ["MY", "", "", "", "X", "", "", ""],
      ["NZ", "", "", "", "X", "", "", ""],
      ["Singapore", "", "", "", "X", "", "", ""],
      ["US", "", "", "", "X", "", "", ""],
      ["WO", "", "", "", "X", "", "", ""],
    ],
    []
  )

  return (
    <Wrapper>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column, colIndex) => (
              <Th key={colIndex} data-centered={colIndex > 1}>
                {column.header}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {data.map((row, rowIndex) => (
            <Tr key={`${rowIndex}`}>
              {columns.map((column, colIndex) => (
                <Td
                  key={`${rowIndex}-${colIndex}`}
                  data-centered={colIndex > 1}
                >
                  {row[colIndex] === "X" ? (
                    <span className="check">&times;</span>
                  ) : (
                    row[colIndex]
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Wrapper>
  )
}
